<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">发票助手</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="凭证号码" v-model="pageParam.params.sn" />
            </le-search-form>
            <le-pagview ref="financeFPZS" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" :tableRef="$refs.financeFPZSList">
                <el-table ref="financeFPZSList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="" label="发票代码" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.invoiceCode || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="发票号码" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.invoiceNumber || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="合计金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.amount) }}{{ row.invoiceType | initInvoiceType }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="myOrderNum" label="发票校验码" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.invoiceCheckCode || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="myOrderNum" label="凭证号码" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.sn || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payeeCompanyName" label="开票日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.dateStr || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="handleEdit(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.invoicePage,
                    method: "post",
                    params: {
                        sn: ''
                    },
                    freshCtrl: 1,
                },
            }
        },
        created () {
            
        },
        filters: {
			initInvoiceType (val) {
				if(val == '10') {
					return '（不含税）'
				}else if(val == '32' || val == '31') {
					return '（含税）'
				}else {
					return ''
				}
			}
		},
        methods: {
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    sn: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['financeFPZS'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handleEdit (datas) {
                this.$prompt('请输入发票凭证号码', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: datas.sn,
                    inputValidator: (res)=>{
                        if(res.length > 20) {
                            return '凭证号码不可超过20位'
                        }
                    }
                }).then(({ value }) => {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.updateInvoice,
                        params: {
                            ...datas,
                            sn: value
                        },
                    })
                    .then(res => {
                        if(res.result.code == 0){
                            this.pageParam.freshCtrl++;
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.result.message)
                    });
                }).catch(() => {
        
                });
            },
            exportfile () {
                this.$exfile({
                    code: 21,
                    fileName: '发票助手导出',
                    params: {
                        ...this.pageParam.params,
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
</style>